<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img
            src="img/avatars/6.jpg"
            class="c-avatar-img "
          />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Account</strong>
    </CDropdownHeader>
    <CDropdownItem>
      <CIcon name="cil-task"/> {{userName}}
    </CDropdownItem>
<!--    <CDropdownItem>-->
<!--      <CIcon name="cil-envelope-open" /> Messages-->
<!--      <CBadge color="success" class="mfs-auto">{{ itemsCount }}</CBadge>-->
<!--    </CDropdownItem>-->
<!--    <CDropdownItem>-->
<!--      <CIcon name="cil-task" /> Tasks-->
<!--      <CBadge color="danger" class="mfs-auto">{{ itemsCount }}</CBadge>-->
<!--    </CDropdownItem>-->
<!--    <CDropdownItem>-->
<!--      <CIcon name="cil-comment-square" /> Comments-->
<!--      <CBadge color="warning" class="mfs-auto">{{ itemsCount }}</CBadge>-->
<!--    </CDropdownItem>-->
<!--    <CDropdownHeader-->
<!--      tag="div"-->
<!--      class="text-center"-->
<!--      color="light"-->
<!--    >-->
<!--      <strong>Settings</strong>-->
<!--    </CDropdownHeader>-->
<!--    <CDropdownItem>-->
<!--      <CIcon name="cil-user" /> Profile-->
<!--    </CDropdownItem>-->
<!--    <CDropdownItem>-->
<!--      <CIcon name="cil-settings" /> Settings-->
<!--    </CDropdownItem>-->
<!--    <CDropdownItem>-->
<!--      <CIcon name="cil-dollar" /> Payments-->
<!--      <CBadge color="secondary" class="mfs-auto">{{ itemsCount }}</CBadge>-->
<!--    </CDropdownItem>-->
<!--    <CDropdownItem>-->
<!--      <CIcon name="cil-file" /> Projects-->
<!--      <CBadge color="primary" class="mfs-auto">{{ itemsCount }}</CBadge>-->
<!--    </CDropdownItem>-->
<!--    <CDropdownDivider/>-->
<!--    <CDropdownItem>-->
<!--      <CIcon name="cil-shield-alt" /> Lock Account-->
<!--    </CDropdownItem>-->
    <CDropdownItem @click="handleLogout">
      <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import {logout} from "@/api/login";
import {removeToken, setToken} from "@/utils/auth";

export default {
  name: 'TheHeaderDropdownAccnt',
    data () {
        return {
            itemsCount: 42,
            userName: this.$store?.getters.user   ? this.$store?.getters.user.name : '',
        }
    },
  methods: {
    // validator(val) {
    //   return val ? val.length >= 4 : false
    // },
    handleLogout() {
      // console.log(9999999)
      this.loading = true
      removeToken();
      window.location.reload()
      // logout().then(response => {
      //   // const data = response.data
      //   // commit('SET_TOKEN', data.token)
      //   removeToken()
      //   this.loading = false
      //   this.$router.push({ path: this.redirect || '/' })
      // }).catch(error => {
      //   console.log(999999999, error.response?.data?.errors)
      //   this.errors = error.response?.data?.errors || ''
      //   this.loading = false
      // })
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>
