export default [
    {
        _name: 'CSidebarNav',
        _children: [
            {
                _name: 'CSidebarNavItem',
                name: 'ダッシュボード',
                to: '/dashboard',
                icon: 'cil-speedometer',
                badge: {
                    color: 'primary',
                    text: 'NEW'
                }
            },
            {
                _name: 'CSidebarNavItem',
                name: '在庫',
                to: '/inventory',
                icon: 'cil-drop'
            },
            // {
            //     _name: 'CSidebarNavItem',
            //     name: '出荷',
            //     to: '/shipping',
            //     icon: 'cil-share'
            // },
            {
                _name: 'CSidebarNavItem',
                name: '出荷・返品段ボール一覧',
                to: '/cardboard',
                icon: 'cil-share'
            },
            {
                _name: 'CSidebarNavItem',
                name: '入荷予定在庫一覧',
                to: '/inspect',
                icon: 'cil-find-in-page'
            },
            {
                _name: 'CSidebarNavItem',
                name: '販売履歴一覧',
                to: '/sales-history',
                icon: 'cib-shopify'
            },
            {
                _name: 'CSidebarNavItem',
                name: '在庫棚卸し',
                to: '/inventory-works',
                icon: 'cil-storage'
            },
            {
                _name: 'CSidebarNavItem',
                name: '商品マスタ',
                to: '/item-masters',
                icon: 'cil-drop'
            },
            {
                _name: 'CSidebarNavItem',
                name: 'ユーザーマスタ',
                to: '/users',
                icon: 'cil-user'
            },
            {
                _name: 'CSidebarNavItem',
                name: 'ショップ',
                to: '/shops',
                icon: 'cil-house'
            },
            {
                _name: 'CSidebarNavItem',
                name: 'サイズ',
                to: '/sizes',
                icon: 'cil-resize-both'
            },
            {
                _name: 'CSidebarNavItem',
                name: 'カラー',
                to: '/colors',
                icon: 'cil-color-palette'
            },
            {
                _name: 'CSidebarNavItem',
                name: 'シーズン',
                to: '/seasons',
                icon: 'cil-sun'
            },
            {
                _name: 'CSidebarNavItem',
                name: '棚',
                to: '/shelves',
                icon: 'cil-storage'
            },
            {
                _name: 'CSidebarNavDropdown',
                name: '分類',
                route: '/page',
                icon: 'cil-drop',
                items: [
                    {
                        name: '大分類',
                        to: '/major-categories',
                    },
                    {
                        name: '中分類',
                        to: '/middle-categories',
                    },
                    {
                        name: '小分類',
                        to: '/minor-categories',
                    }
                ]
            },
            // {
            //   _name: 'CSidebarNavDropdown',
            //   name: '出荷',
            //   route: '/page',
            //   icon: 'cil-puzzle',
            //   items: [
            //       {
            //           name: '出荷',
            //           to: '/shipping',
            //       },
            //       // {
            //       //     name: '返品',
            //       //     to: 'return',
            //       // }
            //   ]
            // },
        ]
    }
]
