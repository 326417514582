export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'ダッシュボード',
        to: '/dashboard',
        icon: 'cil-speedometer',
        badge: {
          color: 'primary',
          text: 'NEW'
        }
      },
      {
        _name: 'CSidebarNavItem',
        name: '在庫',
        to: '/inventory',
        icon: 'cil-drop'
      },
        // {
        //     _name: 'CSidebarNavItem',
        //     name: '出荷',
        //     to: '/shipping',
        //     icon: 'cil-share'
        // },
        {
            _name: 'CSidebarNavItem',
            name: '出荷・返品段ボール一覧',
            to: '/cardboard',
            icon: 'cil-share'
        },
        {
            _name: 'CSidebarNavItem',
            name: '入荷予定在庫一覧',
            to: '/inspect',
            icon: 'cil-find-in-page'
        },
        // {
        //     _name: 'CSidebarNavItem',
        //     name: '返品',
        //     to: '/return',
        //     icon: 'cil-drop'
        // },
        {
            _name: 'CSidebarNavItem',
            name: '販売履歴',
            to: '/sales-history',
            icon: 'cib-shopify'
        },
        {
          _name: 'CSidebarNavItem',
          name: '在庫棚卸し',
          to: '/inventory-works',
          icon: 'cil-storage'
        },
      // {
      //   _name: 'CSidebarNavDropdown',
      //   name: '出荷',
      //   route: '/page',
      //   icon: 'cil-puzzle',
      //   items: [
      //       {
      //           name: '出荷',
      //           to: 'shipping',
      //       },
      //       {
      //           name: '返品',
      //           to: 'return',
      //       }
      //   ]
      // },
    ]
  }
]
