<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <CIcon
        class="c-sidebar-brand-full"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 556 134"
      />
      <CIcon
        class="c-sidebar-brand-minimized"
        name="logo"
        size="custom-size"
        :height="35"
        viewBox="0 0 110 134"
      />
    </CSidebarBrand>

    <CRenderFunction v-if="userShopId === WAREHOUSUE" flat :content-to-render="$options.navWareHouse"/>
    <CRenderFunction v-else flat :content-to-render="$options.navShop"/>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import navWareHouse from './_navWareHouse'
import navShop from './_navShop'
import {WAREHOUSUE} from '@/utils/constance';

export default {
  name: 'TheSidebar',
  navWareHouse,
  navShop,
  data() {
      return {
          userShopId: this.$store.getters.user ? this.$store.getters.user.shop_id : '',
          WAREHOUSUE: WAREHOUSUE
      }
    },
  computed: {
    show () {
      return this.$store.state.sidebarShow
    },
    minimize () {
      return this.$store.state.sidebarMinimize
    }
  }
}
</script>
